@import '../../helpers/styles';

:root {
  @include mobile {    
    font-size: 16px;
  }
  @include desktop {
    font-size: 24px;
  }
  background-color: black;
}

*:focus {
  outline: none;
}

.app-background {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

.app-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  background-color: black;
  overflow: hidden;
}

.app-content {
  width: 100%;
  flex: 1;
  overflow: auto;

  @include desktop {
    min-height: 500pt;
    max-height: 1200pt;
  }
}

@font-face {
    font-family: "Sequel-45";
    src: url("../../../resources/fonts/Sequel-100-Wide-45.ttf") format("truetype");
}
@font-face {
    font-family: "Sequel-55";
    src: url("../../../resources/fonts/Sequel-100-Wide-55.ttf") format("truetype");
}
@font-face {
    font-family: "Sequel-65";
    src: url("../../../resources/fonts/Sequel-100-Wide-65.ttf") format("truetype");
}
@font-face {
    font-family: "Sequel-75";
    src: url("../../../resources/fonts/Sequel-100-Wide-75.ttf") format("truetype");
}
@font-face {
    font-family: "Sequel-85";
    src: url("../../../resources/fonts/Sequel-100-Wide-85.ttf") format("truetype");
}
@font-face {
    font-family: "Sequel-95";
    src: url("../../../resources/fonts/Sequel-100-Wide-95.ttf") format("truetype");
}