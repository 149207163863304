:root {
  background-color: black;
}
@media only screen and (max-width: 600px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min-resolution: 192dpi) and (max-width: 600px), only screen and (min-resolution: 2dppx) and (max-width: 600px) {
  :root {
    font-size: 16px;
  }
}
@media only screen and (min-width: 601px), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min-resolution: 192dpi) and (min-width: 601px), only screen and (min-resolution: 2dppx) and (min-width: 601px) {
  :root {
    font-size: 24px;
  }
}

*:focus {
  outline: none;
}

.app-background {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  width: 100vw;
  height: 100vh;
  max-height: -webkit-fill-available;
  background-color: black;
  overflow: hidden;
}

.app-content {
  width: 100%;
  flex: 1;
  overflow: auto;
}
@media only screen and (min-width: 601px), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min-resolution: 192dpi) and (min-width: 601px), only screen and (min-resolution: 2dppx) and (min-width: 601px) {
  .app-content {
    min-height: 500pt;
    max-height: 1200pt;
  }
}

@font-face {
  font-family: "Sequel-45";
  src: url("../../../resources/fonts/Sequel-100-Wide-45.ttf") format("truetype");
}
@font-face {
  font-family: "Sequel-55";
  src: url("../../../resources/fonts/Sequel-100-Wide-55.ttf") format("truetype");
}
@font-face {
  font-family: "Sequel-65";
  src: url("../../../resources/fonts/Sequel-100-Wide-65.ttf") format("truetype");
}
@font-face {
  font-family: "Sequel-75";
  src: url("../../../resources/fonts/Sequel-100-Wide-75.ttf") format("truetype");
}
@font-face {
  font-family: "Sequel-85";
  src: url("../../../resources/fonts/Sequel-100-Wide-85.ttf") format("truetype");
}
@font-face {
  font-family: "Sequel-95";
  src: url("../../../resources/fonts/Sequel-100-Wide-95.ttf") format("truetype");
}/*# sourceMappingURL=App.css.map */