@import '../../helpers/styles';

.header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: #FFF;
    background-color: rgba(255,255,255,0.05);
    z-index: 1;

    @include desktop {
        height: 74px;
        min-height: 74px;
        padding-left: 40px;
        padding-right: 40px;
    }

    @include mobile {
        height: 44px;
        min-height: 44px;
        padding-left: 12px;
        padding-right: 12px;
    }
}

.header-home-text {
    color: #DBFF00;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0 0;
    font-family: 'Sequel-75';

    @include desktop {
        font-size: 18px !important;
    }

    @include mobile {
        font-size: 14px !important;
    }
}

.Header-menu {
    border: none;
    cursor: pointer;
}