.remayk-gen-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 600px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min-resolution: 192dpi) and (max-width: 600px), only screen and (min-resolution: 2dppx) and (max-width: 600px) {
  .remayk-gen-page-container {
    align-items: stretch;
  }
}

.remayk-gen-page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(20, 20, 20, 0.8);
  border-radius: 1.5rem;
  max-width: min(500pt, 100%);
  z-index: 1;
}
@media only screen and (min-width: 601px), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min-resolution: 192dpi) and (min-width: 601px), only screen and (min-resolution: 2dppx) and (min-width: 601px) {
  .remayk-gen-page-content {
    margin: 1rem 1rem 0.5rem 1rem;
    padding: 1rem;
    width: 500pt;
  }
}
@media only screen and (max-width: 600px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min-resolution: 192dpi) and (max-width: 600px), only screen and (min-resolution: 2dppx) and (max-width: 600px) {
  .remayk-gen-page-content {
    padding: 1rem;
    margin: 1rem;
  }
}

.remayk-gen-page-title {
  font-family: "Sequel-75";
  font-size: 1rem;
  color: white;
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;
}

.remayk-gen-page-create-button {
  width: 100% !important;
  margin-top: 2rem !important;
}

.remayk-gen-page-audio {
  margin-top: 1rem;
}/*# sourceMappingURL=RemaykGenPage.css.map */