.SongSelector-container {
  width: 100%;
  color: white;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.SongSelector-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 2.5rem;
  padding-left: 1rem;
  padding-right: 0.2rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  font-family: "Sequel-45";
  font-size: 1rem;
  background-color: #222;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: clip;
  overflow: auto;
}

.SongSelector-title {
  flex: 1;
}

.SongSelector-image {
  width: 1.5rem;
  margin-right: 0.5rem;
}

.SongSelector-arrow {
  height: 0.5rem;
  width: 1rem;
  margin: 0.5rem;
}

.SongSelector-options-container {
  overflow-y: scroll;
  font-size: 1rem;
  font-family: "Sequel-45";
  max-height: 80vh;
}
@media only screen and (min-width: 601px), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min-resolution: 192dpi) and (min-width: 601px), only screen and (min-resolution: 2dppx) and (min-width: 601px) {
  .SongSelector-options-container {
    width: max(500pt, 50vw);
    padding-bottom: 54px;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 600px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min-resolution: 192dpi) and (max-width: 600px), only screen and (min-resolution: 2dppx) and (max-width: 600px) {
  .SongSelector-options-container {
    box-sizing: border-box;
    align-self: stretch;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 54px;
  }
}

.SongSelector-options {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  z-index: 999;
  background-color: #222;
  border-radius: 0.5rem;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.SongSelector-options-container::-webkit-scrollbar {
  display: none;
}

.SongSelector-upload {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #FFF;
  height: 2.5rem;
  background-color: #141414;
  border-radius: 0.5rem;
  margin: 0.2rem 0.3rem;
  position: relative;
  padding: 0.5rem 0.5rem;
  cursor: pointer;
}

.SongSelector-upload-image {
  position: relative;
  margin-right: 0.5rem;
  height: 2.5rem;
  width: 2.5rem;
}

.SongSelector-upload-hint {
  font-family: "Sequel-45";
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.5333333333);
  margin-top: 0.1rem;
}

.SongSelector-catalog-song {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #FFF;
  height: 2.5rem;
  background-color: #141414;
  border-radius: 0.5rem;
  margin: 0.2rem 0.3rem;
  position: relative;
  padding: 0.5rem 0.5rem;
}

.SongSelector-catalog-labels {
  cursor: pointer;
  flex: 1;
}

.SongSelector-catalog-images {
  position: relative;
  margin-right: 0.5rem;
  margin-left: 0.1rem;
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;
}

.SongSelector-catalog-play {
  position: absolute;
  width: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.SongSelector-catalog-author {
  font-family: "Sequel-45";
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.5333333333);
  margin-top: 0.1rem;
}

.SongSelector-catalog-image {
  color: white;
  position: absolute;
  width: 100%;
}

.SongSelector-url-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #141414;
  border-radius: 0.5rem;
  margin: 0.2rem 0.3rem;
  position: relative;
  padding: 0.5rem 0.5rem;
}

.SongSelector-url-image {
  width: 2.5rem;
}

.SongSelector-url-input {
  background-color: #141414;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  color: #FFF;
  font-family: "Sequel-45";
  font-size: 1rem;
  flex: 1;
  padding: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 2rem;
}

.SongSelector-url-done {
  color: white;
  cursor: pointer;
}

.SongSelector-url-done-disabled {
  color: #333;
}/*# sourceMappingURL=SongSelector.css.map */