.MaykButton {
  background-color: #DBFF00 !important;
  color: black !important;
  text-transform: "none" !important;
  font-family: "Sequel-75" !important;
}
@media only screen and (min-width: 601px), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min-device-pixel-ratio: 2) and (min-width: 601px), only screen and (min-resolution: 192dpi) and (min-width: 601px), only screen and (min-resolution: 2dppx) and (min-width: 601px) {
  .MaykButton {
    height: 64px !important;
    min-height: 64px !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
    border-radius: 16px !important;
    font-size: 20px !important;
    line-height: 26px !important;
  }
}
@media only screen and (max-width: 600px), only screen and (-webkit-min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min-device-pixel-ratio: 2) and (max-width: 600px), only screen and (min-resolution: 192dpi) and (max-width: 600px), only screen and (min-resolution: 2dppx) and (max-width: 600px) {
  .MaykButton {
    height: 48px !important;
    min-height: 48px !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
    border-radius: 12px !important;
    font-size: 14px !important;
  }
}/*# sourceMappingURL=MaykButton.css.map */