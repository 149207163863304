$mobile: 600px;
$dekstop: 601px;

@mixin mobile {
  @media
  only screen                                              and (max-width: $mobile),
  only screen and (-webkit-min-device-pixel-ratio: 2)      and (max-width: $mobile),
  only screen and (   min--moz-device-pixel-ratio: 2)      and (max-width: $mobile),
  only screen and (     -o-min-device-pixel-ratio: 2/1)    and (max-width: $mobile),
  only screen and (        min-device-pixel-ratio: 2)      and (max-width: $mobile),
  only screen and (                min-resolution: 192dpi) and (max-width: $mobile),
  only screen and (                min-resolution: 2dppx)  and (max-width: $mobile) { 
    @content
  }
};

@mixin desktop {
  @media
  only screen                                              and (min-width: $dekstop),
  only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: $dekstop),
  only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: $dekstop),
  only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: $dekstop),
  only screen and (        min-device-pixel-ratio: 2)      and (min-width: $dekstop),
  only screen and (                min-resolution: 192dpi) and (min-width: $dekstop),
  only screen and (                min-resolution: 2dppx)  and (min-width: $dekstop) { 
    @content
  }
};