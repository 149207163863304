@import '../../../SharedStyles/styles';

.MaykButton {
    background-color: #DBFF00 !important;
    color: black !important;
    text-transform: 'none' !important;
    font-family: 'Sequel-75' !important;

    @include desktop {
        height: 64px !important;
        min-height: 64px !important;
        padding-left: 28px !important;
        padding-right: 28px !important;
        border-radius: 16px !important;
        font-size: 20px !important;
        line-height: 26px !important;
    }

    @include mobile {
        height: 48px !important;
        min-height: 48px !important;
        padding-left: 28px !important;
        padding-right: 28px !important;
        border-radius: 12px !important;
        font-size: 14px !important;
    }
}